import z from 'zod';

const publicEnvSchema = z.object({
  NEXT_PUBLIC_ENVIRONMENT_NAME: z.union([
    z.literal('development'),
    z.literal('mock'),
    z.literal('preview'),
    z.literal('production'),
    z.literal('preview'),
    z.literal('staging'),
    z.literal('test'),
  ]),
  NEXT_PUBLIC_API_MOCKING: z.union([z.literal('disabled'), z.literal('enabled')]),
  // If the value is "true", we want to set it to true, otherwise we want to set it to false.
  NEXT_PUBLIC_HIDE_DRAFTS: z.string().transform((val) => val === 'true'),
  NEXT_PUBLIC_LOGGING: z.union([z.literal('disabled'), z.literal('enabled')]),
  NEXT_PUBLIC_DEBUG_MODE: z.union([z.literal('disabled'), z.literal('enabled')]),
  // If the value is "false", we want to set it to false, otherwise we want to set it to the value.
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: z.string().transform((val) => (val === 'false' ? false : val)),
  NEXT_PUBLIC_SANITY_PROJECT_ID: z.string(),
  NEXT_PUBLIC_SANITY_DATASET: z.string(),
  NEXT_PUBLIC_BRAINTREE_KEY: z.string(),
  NEXT_PUBLIC_PAYPAL_CLIENT_ID: z.string(),
  NEXT_PUBLIC_BRAAVOS_URL: z.string(),
  NEXT_PUBLIC_CONNECT_URL: z.string(),
  NEXT_PUBLIC_EMC_CONTENT_URL: z.string(),
  NEXT_PUBLIC_STRIPE_KEY: z.string(),
  NEXT_PUBLIC_BRAAVOS_RECAPTCHA_KEY: z.string(),
  NEXT_PUBLIC_USER_API_RECAPTCHA_KEY: z.string(),
  NEXT_PUBLIC_USER_URL: z.string(),
  NEXT_PUBLIC_PROFILE_URL: z.string(),
  NEXT_PUBLIC_AUTH_URL: z.string(),
  NEXT_PUBLIC_RAZORPAY_KEY: z.string(),
  NEXT_PUBLIC_BUILD_SHA: z.string().optional(),
  NEXT_PUBLIC_CLASSROOM_CONTENT_URL: z.string(),
  NEXT_PUBLIC_TRUSTPILOT_KEY: z.string(),
  NEXT_PUBLIC_LEARN_URL: z.string(),
  NEXT_PUBLIC_ENROLLMENTS_URL: z.string(),
  NEXT_PUBLIC_TRANSCEND_BUNDLE_ID: z.string(),
  NEXT_PUBLIC_UNIFIED_CATALOG_URL: z.string(),
  NEXT_PUBLIC_UMP_URL: z.string(),
  NEXT_PUBLIC_DEBUG_ANALYTICS: z.string().optional(),
  NEXT_PUBLIC_EXPERIENCE_URL: z.string(),
  NEXT_PUBLIC_RECOMMENDATIONS_URL: z.string(),
  NEXT_PUBLIC_QUALIFIED_KEY: z.string().optional(),
  NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY: z.string(),
  NEXT_PUBLIC_PROGRESS_URL: z.string(),
  NEXT_PUBLIC_GAMIFICATION_URL: z.string(),
  NEXT_PUBLIC_SUPPORT_URL: z.string(),
  NEXT_PUBLIC_GREENHOUSE_API: z.string(),
  NEXT_PUBLIC_NOTIFICATION_PREFERENCES_URL: z.string(),
  NEXT_PUBLIC_REVIEW_URL: z.string(),
  NEXT_PUBLIC_PLANS_URL: z.string(),
  NEXT_PUBLIC_WORKERA_LOGIN_URL: z.string(),
  NEXT_PUBLIC_RATINGS_URL: z.string(),
  NEXT_PUBLIC_APPLICATIONS_URL: z.string(),
});

const publicEnvValidationResult = publicEnvSchema.safeParse({
  NEXT_PUBLIC_ENVIRONMENT_NAME: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME,
  NEXT_PUBLIC_API_MOCKING: process.env.NEXT_PUBLIC_API_MOCKING,
  NEXT_PUBLIC_HIDE_DRAFTS: process.env.NEXT_PUBLIC_HIDE_DRAFTS,
  NEXT_PUBLIC_LOGGING: process.env.NEXT_PUBLIC_LOGGING,
  NEXT_PUBLIC_DEBUG_MODE: process.env.NEXT_PUBLIC_DEBUG_MODE,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
  NEXT_PUBLIC_BRAINTREE_KEY: process.env.NEXT_PUBLIC_BRAINTREE_KEY,
  NEXT_PUBLIC_PAYPAL_CLIENT_ID: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
  NEXT_PUBLIC_BRAAVOS_URL: process.env.NEXT_PUBLIC_BRAAVOS_URL,
  NEXT_PUBLIC_CONNECT_URL: process.env.NEXT_PUBLIC_CONNECT_URL,
  NEXT_PUBLIC_EMC_CONTENT_URL: process.env.NEXT_PUBLIC_EMC_CONTENT_URL,
  NEXT_PUBLIC_STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY,
  NEXT_PUBLIC_BRAAVOS_RECAPTCHA_KEY: process.env.NEXT_PUBLIC_BRAAVOS_RECAPTCHA_KEY,
  NEXT_PUBLIC_USER_API_RECAPTCHA_KEY: process.env.NEXT_PUBLIC_USER_API_RECAPTCHA_KEY,
  NEXT_PUBLIC_USER_URL: process.env.NEXT_PUBLIC_USER_URL,
  NEXT_PUBLIC_PROFILE_URL: process.env.NEXT_PUBLIC_PROFILE_URL,
  NEXT_PUBLIC_AUTH_URL: process.env.NEXT_PUBLIC_AUTH_URL,
  NEXT_PUBLIC_RAZORPAY_KEY: process.env.NEXT_PUBLIC_RAZORPAY_KEY,
  NEXT_PUBLIC_BUILD_SHA: process.env.NEXT_PUBLIC_BUILD_SHA,
  NEXT_PUBLIC_CLASSROOM_CONTENT_URL: process.env.NEXT_PUBLIC_CLASSROOM_CONTENT_URL,
  NEXT_PUBLIC_TRUSTPILOT_KEY: process.env.NEXT_PUBLIC_TRUSTPILOT_KEY,
  NEXT_PUBLIC_LEARN_URL: process.env.NEXT_PUBLIC_LEARN_URL,
  NEXT_PUBLIC_ENROLLMENTS_URL: process.env.NEXT_PUBLIC_ENROLLMENTS_URL,
  NEXT_PUBLIC_TRANSCEND_BUNDLE_ID: process.env.NEXT_PUBLIC_TRANSCEND_BUNDLE_ID,
  NEXT_PUBLIC_UNIFIED_CATALOG_URL: process.env.NEXT_PUBLIC_UNIFIED_CATALOG_URL,
  NEXT_PUBLIC_UMP_URL: process.env.NEXT_PUBLIC_UMP_URL,
  NEXT_PUBLIC_DEBUG_ANALYTICS: process.env.NEXT_PUBLIC_DEBUG_ANALYTICS,
  NEXT_PUBLIC_EXPERIENCE_URL: process.env.NEXT_PUBLIC_EXPERIENCE_URL,
  NEXT_PUBLIC_RECOMMENDATIONS_URL: process.env.NEXT_PUBLIC_RECOMMENDATIONS_URL,
  NEXT_PUBLIC_QUALIFIED_KEY: process.env.NEXT_PUBLIC_QUALIFIED_KEY,
  NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY,
  NEXT_PUBLIC_PROGRESS_URL: process.env.NEXT_PUBLIC_PROGRESS_URL,
  NEXT_PUBLIC_GAMIFICATION_URL: process.env.NEXT_PUBLIC_GAMIFICATION_URL,
  NEXT_PUBLIC_SUPPORT_URL: process.env.NEXT_PUBLIC_SUPPORT_URL,
  NEXT_PUBLIC_GREENHOUSE_API: process.env.NEXT_PUBLIC_GREENHOUSE_API,
  NEXT_PUBLIC_NOTIFICATION_PREFERENCES_URL: process.env.NEXT_PUBLIC_NOTIFICATION_PREFERENCES_URL,
  NEXT_PUBLIC_REVIEW_URL: process.env.NEXT_PUBLIC_REVIEW_URL,
  NEXT_PUBLIC_PLANS_URL: process.env.NEXT_PUBLIC_PLANS_URL,
  NEXT_PUBLIC_WORKERA_LOGIN_URL: process.env.NEXT_PUBLIC_WORKERA_LOGIN_URL,
  NEXT_PUBLIC_RATINGS_URL: process.env.NEXT_PUBLIC_RATINGS_URL,
  NEXT_PUBLIC_APPLICATIONS_URL: process.env.NEXT_PUBLIC_APPLICATIONS_URL,
});

if (!publicEnvValidationResult.success) {
  const error = publicEnvValidationResult.error;

  console.error({
    file: 'src/features/environment/public.ts',
    function: 'publicEnv',
    description: 'Invalid environment variables.',
    error: error,
  });

  throw new Error('Invalid environment variables.');
}

export const publicEnv = publicEnvValidationResult.data;

export const reactDatePickerGlobalStyles = {
  '.react-datepicker': {
    border: '1px solid #c7c7c7 !important',
  },

  '.react-datepicker__month-container': {
    width: '352px',
    fontSize: '1rem',
  },

  '.react-datepicker__day-names': {
    textTransform: 'uppercase',
    fontWeight: '600',
  },

  '.react-datepicker__day-name, .react-datepicker__day': {
    width: '2.7rem !important',
    lineHeight: '2.7rem !important',
  },

  '.react-datepicker__day--highlighted': {
    backgroundColor: '#e9e8ff !important',
    color: 'black !important',
  },

  '.react-datepicker__day--highlighted.react-datepicker__day--disabled': {
    backgroundColor: 'white !important',
    color: '#808080 !important',
    border: '1px solid #dfdfdf !important',
  },

  '.react-datepicker__day--highlighted.react-datepicker__day--disabled:hover': {
    backgroundColor: 'white !important',
    color: '#808080 !important',
    border: '1px solid #dfdfdf !important',
  },

  '.react-datepicker__day--selected': {
    color: 'white !important',
    backgroundColor: '#2015ff !important',
  },

  '.react-datepicker__day--keyboard-selected': {
    backgroundColor: 'white',
    outline: '2px solid #7973FF !important',
  },

  '.react-datepicker__day:hover': {
    color: 'white !important',
    backgroundColor: '#4D44FF !important',
  },

  '.react-datepicker__day:focus': {
    outline: 'none',
  },

  '.react-datepicker__day--disabled:hover': {
    backgroundColor: 'white !important',
    color: '#808080 !important',
  },

  '.react-datepicker__day--disabled': {
    backgroundColor: 'white !important',
    color: '#808080 !important',
  },

  '.react-datepicker__day': {
    borderRadius: '100% !important',
  },
  '.react-datepicker__day--today': {
    border: '1px solid #2015ff !important',
  },

  '.react-datepicker__header': {
    backgroundColor: 'white !important',
    borderBottom: 'none !important',
  },
};
